import request from '../request'

//合作企业申请列表
export const cooperative_enterprise_index_request = p => {
    return request({
        method:'GET',
        url:'cooperative_enterprise/index',
        params: p
    })
}

//发起合作企业申请
export const cooperative_enterprise_add_request = d => {
    return request({
        method:'POST',
        url:'cooperative_enterprise/add',
        data: d
    })
}

//删除合作企业申请
export const cooperative_enterprise_del_request = id => {
    return request({
        method:'DELETE',
        url:'cooperative_enterprise/del',
        data: {
            id: id
        }
    })
}

//修改合作企业申请
export const cooperative_enterprise_edit_request = data => {
    return request({
        method:'PUT',
        url:'cooperative_enterprise/edit',
        data
    })
}

//获取合作企业申请详情
export const cooperative_enterprise_details_request = id => {
    return request({
        method:'GET',
        url:'cooperative_enterprise/details',
        params: {
            id
        }
    })
}

// 提交合作企业申请
export const cooperative_enterprise_to_approval_request = d => {
    return request({
        method:'PUT',
        url:'cooperative_enterprise/to_approval',
        data: {
            id:d.id,
            note:d.note
        }
    })
}

// 查看合作企业申请审批记录
export const cooperative_enterprise_sel_approval_request = id => {
    return request({
        method:'GET',
        url:'cooperative_enterprise/sel_approval',
        params: {
            id
        }
    })
}

// 审批合作企业申请
export const cooperative_enterprise_approval_request = d => {
    return request({
        method:'PUT',
        url:'cooperative_enterprise/approval',
        data: {
            id: d.id,
            opinion: d.opinion,
            note: d.note,
            other: d.other
        }
    })
}

// 撤回合作企业申请
export const cooperative_enterprise_withdraw_request = d => {
    return request({
        method:'PUT',
        url:'cooperative_enterprise/withdraw',
        data: {
            id: d.id
        }
    })
}

// 打印合作企业申请
export const cooperative_enterprise_print_request = id => {
    return request({
        method:'PUT',
        url:'cooperative_enterprise/print',
        data: {
            id
        }
    })
}