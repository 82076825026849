<template>
    <div>
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name"></nav-bar-component>
        <van-cell-group inset >
            <van-cell title="单号" :value="details.name" />
            <van-cell title="审批状态">
                <template #right-icon>
                    <van-tag plain color="#ADADAD" v-if="details.state===0">待提交</van-tag>
                    <van-tag plain type="primary" v-if="details.state===1">审批中</van-tag>
                    <van-tag plain type="success" v-if="details.state===2">已通过</van-tag>
                    <van-tag plain type="danger" v-if="details.state===3">已驳回</van-tag>
                </template>
            </van-cell>
            <van-cell title="企业名称" :value="details.enterprise_name" />
            <van-cell title="企业类型" :value="details.type_name" />
            <van-cell title="税务登记号" :value="details.taxation" />
            <van-cell title="联系人" :value="details.contacts" />
            <van-cell title="联系电话" :value="details.phone" />
            <van-cell title="经营范围" :value="details.nature_of_business" />
            <van-cell title="企业地址" :value="details.address" />
            <van-cell class="note_cell" title="合作状态">
                <template #right-icon>
                    <van-tag plain type="danger" v-if="details.status===0">禁用</van-tag>
                    <van-tag plain type="warning" v-if="details.status===1">临时</van-tag>
                    <van-tag plain type="success" v-if="details.status===2">长期</van-tag>
                </template>
            </van-cell>
            <van-cell class="note_cell" title="是否签合同">
                <template #right-icon>
                    <van-tag round type="danger" v-if="details.is_cooperation===0">否</van-tag>
                    <van-tag round type="primary" v-if="details.is_cooperation===1">是</van-tag>
                </template>
            </van-cell>
            <van-cell v-if="details.is_cooperation===0" title="不签原因" :value="details.contract_date" />
            <van-cell v-else-if="details.is_cooperation===1" title="签订日期" :value="details.contract_date+'日之内'" />
            <van-cell title="申请原因" :value="details.content" />
            <van-cell title="督办人" :value="details.supervisor_name" />
            <van-cell title="业务类型" :value="details.business_type_name" />
            <van-cell title="业务描述" :value="details.business_mark" />
            <van-cell title="主要客户" :value="details.has_client" />
            <van-cell title="优势项目" :value="details.project" />
            <van-cell title="账期类型" :value="details.account_period_type_name" />
            <van-cell title="账期天数" :value="details.account_period_day" />
            <van-cell title="银行卡号(RMB)" :value="details.with_bank_card_rmb_account_card" />
            <van-cell title="开户行" :value="details.with_bank_card_rmb_account_bank" />
            <van-cell title="户主" :value="details.with_bank_card_rmb_account_name" />
            <van-cell title="银行卡号(USD)" :value="details.with_bank_card_usd_account_card" v-if="details.with_bank_card_usd"/>
            <van-cell title="开户行" :value="details.with_bank_card_usd_account_bank" v-if="details.with_bank_card_usd"/>
            <van-cell title="户主" :value="details.with_bank_card_usd_account_name" v-if="details.with_bank_card_usd"/>
            <van-cell title="附件">
                <template #label>
                    <uploader-component
                        :farr="details.other"
                        :show_upload="false"
                        :deletable="false"
                    ></uploader-component>
                </template>
            </van-cell>
        </van-cell-group>
        <div class="sub_button">
            <van-button v-if="to_approval_auth" round block type="primary" @click="to_approval()">提交审批</van-button>
        </div>
        <div class="sub_button">
            <van-button v-if="sel_approval_auth" round block type="warning" @click="sel_approval()">查看审批</van-button>
        </div>
        <div class="sub_button">
            <van-button v-if="withdraw_auth" round block type="danger" @click="CooperativeEnterpriseWithdraw()">审批撤回</van-button>
        </div>
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'
import SelectComponent from '@/components/common/SelectComponent.vue'
import UploaderComponent from '@/components/common/UploaderComponent.vue'

import { 
    cooperative_enterprise_details_request,
    cooperative_enterprise_to_approval_request,
    cooperative_enterprise_withdraw_request
    } from '@/network/business/CooperativeEnterprise.js'

export default {
    name:'CooperativeEnterpriseDetailsComponent',
    data(){
        return {
            nav_bar: {
                left: true,
                right: {
                    type: '',
                    name: ''
                },
            },
            id: 0,
            details: {
                other: []
            },
            all_enterprise: [],
            all_staff: [],
            //提交
            to_approval_auth: false,
            //查看审批
            sel_approval_auth: false,
            //审批
            ApprovalAuth: false,
            //撤回审批
            withdraw_auth: false
        }
    },
    computed:{
        my_nav_bar_name() {
            return '合作企业申请详情'
        }
    },
    methods:{
        get_cooperative_enterprise_details() {
            this.$store.commit('true_loading')
            cooperative_enterprise_details_request(this.id)
                .then((s) => {
                    if (s.status === 0) {
                        this.details = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => {
                    this.$store.commit('false_loading')
                    this.is_auth()
                })
        },
        back_details() {
            this.get_cooperative_enterprise_details()
        },
        sel_approval() {
            this.$router.push({
                name: 'ApprovalComponent',
                query: {
                    type: 15,
                    id: this.id,
                    ApprovalAuth: this.ApprovalAuth
                }
            })
        },
        to_approval() {
            this.$dialog.confirm({
                title: '温馨提示',
                message: '是否确定提交审批？',
            })
            .then(() => {
                this.$store.commit('true_loading')
                cooperative_enterprise_to_approval_request({id:this.id,note:''})
                        .then((s) => {
                            if (s.status === 0) {
                                this.$toast.success('已提交!')
                                this.get_cooperative_enterprise_details()
                            } else {
                                this.$toast.fail(s.msg)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(() => this.$store.commit('false_loading'))
            })
            .catch(() => {});
        },
        CooperativeEnterpriseWithdraw() {
            this.$dialog.confirm({
                title: '温馨提示',
                message: '是否确定撤回？（撤回后再发起需要重新走流程）',
            })
            .then(() => {
                this.$store.commit('true_loading')
                cooperative_enterprise_withdraw_request({id:this.id})
                        .then((s) => {
                            if (s.status === 0) {
                                this.$toast.success('已撤回!')
                                this.get_cooperative_enterprise_details()
                            } else {
                                this.$toast.fail(s.msg)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(() => this.$store.commit('false_loading'))
            })
            .catch(() => {});
        },
        is_auth() {
            this.to_approval_auth = false
            this.sel_approval_auth = false
            this.ApprovalAuth = false
            this.withdraw_auth = false

            if(this.details.state === 0) {
                //判断创建人或申请人是否为自己
                if((this.details.staff_id === this.$store.state.user.id) || (this.details.create_staff === this.$store.state.user.id)) {
                    this.to_approval_auth = this.$_has('提交合作企业申请')
                }
            } else if(this.details.state === 1) {
                 //判断创建人或申请人是否为自己
                if((this.details.staff_id === this.$store.state.user.id) || (this.details.create_staff === this.$store.state.user.id)) {
                    this.withdraw_auth = this.$_has('撤回合作企业申请')
                }
                //判断当前审批人是否为自己
                if(this.details.current_examine_staff_id === this.$store.state.user.id) {
                    this.ApprovalAuth = this.$_has('审批合作企业申请')
                }
                this.sel_approval_auth = this.$_has('查看合作企业申请审批记录')
            } else if(this.details.state === 2) {
                this.sel_approval_auth = this.$_has('查看合作企业申请审批记录')
            } else if(this.details.state === 3) {
                //判断创建人或申请人是否为自己
                if((this.details.staff_id === this.$store.state.user.id) || (this.details.create_staff === this.$store.state.user.id)) {
                    this.to_approval_auth = this.$_has('提交合作企业申请')
                }
                this.sel_approval_auth = this.$_has('查看合作企业申请审批记录')
            }
        },
    },
    filters:{},
    props:{},
    created(){
        this.id = this.$route.query.id
        this.get_cooperative_enterprise_details()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        LoadingComponent,
        NavBarComponent,
        SelectComponent,
        UploaderComponent
    },
    watch:{}
}
</script>

<style>
.sub_button {
    margin: 0.5rem;
}
.van-cell__value {
    white-space: pre-line
}
</style>